export default {

    "1":"1 mês",
    "2":"2 meses",
    "3":"3 meses",
    "6":"Entre 3 e 6 meses",
    "12":"Entre 6 e 12 meses",
    "24":"Entre 1 e 2 anos",
    "36":"Entre 2 e 3 anos",
    "48":"Entre 3 e 4 anos",
    "60":"Mais de 4 anos",
}