<template>
  <div>
    <MenuSuperior></MenuSuperior>
    <div v-if="loading" class="p-5">Carregando...</div>

    <div
      v-if="!loading && this.anuncio.fotos.length == 0"
      class="gallery-container align-items-center"
    >
      <!-- Nenhuma imagem -->
      <img src="/img/empty_img2.jpg" />
    </div>

    <!-- Galeria Desktop -->
    <div
      v-if="!loading && this.anuncio.fotos.length > 0"
      class="gallery-container d-none d-lg-block"
    >
      <!-- <img
        v-for="(foto,index) in this.anuncio.fotos"
        v-img="{group:'anuncio-images'}"
        :src="foto"
        :key="'anuncio-image'+index"
        :class="{'d-none': index > 3}"
      /> -->
      <swiper v-if="this.anuncio.fotos.length > 0" :options="swiperOptionDektop">
      <swiper-slide v-for="(foto,index) in this.anuncio.fotos" :key="'anuncio-desktop-image'+index">    
        <div class="img-galeria" :style="{backgroundImage: 'url('+foto+')'}">
          <img v-img="{group:'anuncio-images'}" :src="foto" style="opacity: 0" />
        </div>
      </swiper-slide>      
      <div class="swiper-pagination" slot="pagination"></div>
      <div class="swiper-button-prev swiper-button-white" slot="button-prev"></div>
     <div class="swiper-button-next swiper-button-white" slot="button-next"></div>
    </swiper>

    </div>

    <!-- Galeria Mobile -->
    <swiper
      v-if="!loading && this.anuncio.fotos.length > 0"
      :options="swiperOption"
      class="d-block d-lg-none"
    >
      <swiper-slide v-for="(foto,index) in this.anuncio.fotos" :key="'anuncio-mobile-image'+index">
        <div class="img-galeria" :style="{ backgroundImage: 'url('+foto+')' }"></div>
      </swiper-slide>      
      <div class="swiper-pagination" slot="pagination"></div>
      <div class="swiper-button-prev swiper-button-white" slot="button-prev"></div>
     <div class="swiper-button-next swiper-button-white" slot="button-next"></div>
    </swiper>

    <div class="container mt-3">
      <div class="row" v-if="!loading">
        <div class="col-lg-8 descricao-produto-container mb-4">
          <BlocoDescricaoAnuncio :anuncio="anuncio"></BlocoDescricaoAnuncio>
        </div>
        <div class="col-lg-4 valor-produto-container mb-4">
          <BlocoValorAnuncio :anuncio="anuncio"></BlocoValorAnuncio>
        </div>
      </div>

      <BlocosAnuncios titulo="Você também pode gostar" imagem="/img/bike_speed.jpg"></BlocosAnuncios>

      <BlocosConheca></BlocosConheca>
    </div>

    <Rodape class="mt-5" />
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import Swal from "sweetalert2";
import axios from "axios";

import config from "../config";
import captureError from "../helpers/captureError";

import MenuSuperior from "../components/MenuSuperior";
import BlocoDescricaoAnuncio from "../components/BlocoDescricaoAnuncio";
import BlocoValorAnuncio from "../components/BlocoValorAnuncio";
import BlocosAnuncios from "../components/BlocosAnuncios";
import BlocosConheca from "../components/BlocosConheca";
import Rodape from "../components/Rodape";

export default {
  components: {
    MenuSuperior,
    BlocoDescricaoAnuncio,
    BlocoValorAnuncio,
    BlocosAnuncios,
    BlocosConheca,
    Rodape,

    Swiper,
    SwiperSlide
  },

  data() {
    return {
      swiperOption: {
        loop:true,
        slidesPerView: 1,
        spaceBetween: 7,
        pagination: {
          el: ".swiper-pagination",
          clickable: true
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
          }
      },
      swiperOptionDektop: {
        loop:false,
        centerInsufficientSlides: true,
        slidesPerView: 3,
        spaceBetween: 7,
        pagination: {
          el: ".swiper-pagination",
          clickable: true
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
          }
      },
      loading: true,
      anuncio: undefined,
      idAnuncio: undefined
    };
  },

  created() {
    this.idAnuncio = this.$route.params.idAnuncio;

    this.popularAnuncio();
  },
  methods: {
    async popularAnuncio() {
      try {
        this.loading = true;

        var response = await axios.get(
          config.API_URL + "/anuncio/" + this.idAnuncio
        );
        this.anuncio = response.data;
        this.loading = false;
      } catch (e) {
        this.loading = false;

        if (e.response?.data?.desativado) {
          await Swal.fire({
            icon: "warning",
            title: "Este produto foi desativado pelo anunciante",
             text:
              "Utilize o campo de pesquisa e encontre outros produtos que você pode gostar também."
          });
          this.$router.replace('/');
        } else {
          captureError(e);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text:
              "Não foi possível carregar o produto. Tente novamente mais tarde ou contate o suporte."
          });
        }
      }
    }
  }
};
</script>
<style lang="less" scoped>
@import "../styles/variaveis";

.gallery-container > img {
  height: 100%;
  width: auto;
  object-fit: cover;
  padding-right: 7px;
}
.gallery-container {
  background-color: @light-gray-color;
  height: 400px;
  display: flex;
  justify-content: center;
  overflow-x: hidden;
  white-space: nowrap;
}

.img-galeria {
  background-position-y: center;
  background-size: cover;
  height: 400px;
  width: 100%;
}


// @media (min-width: 960px) {
//   .descricao-produto-container {
//     margin-top: -120px;
//     /* margin-top: -60px; */
//   }

//   .valor-produto-container {
//     margin-top: -60px;
//   }
// }

.swiper-button-next:after, .swiper-container-rtl .swiper-button-prev:after{
    background-color: #0000006e;
    padding: 0px 11px 0px 13px;;
    font-size: 24px;
    border-radius: 18px;    
}

.swiper-button-prev:after, .swiper-container-rtl .swiper-button-next:after {
 background-color: #0000006e;
    padding: 0px 13px 0px 11px;;
    font-size: 24px;
    border-radius: 18px;    
}



.swiper-button-next, .swiper-button-prev{
outline: none;
}
</style>