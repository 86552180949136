<template>
  <div class="card shadow">
    <div class="card-body text-left">
      <strong>Valor de venda</strong>
      <h2 class="text-success fonte-numero">
        {{ anuncio.valorVenda | formatMoney }}
      </h2>
      <hr />
      <div class="row">
        <div class="col"><strong>Sobre o vendedor</strong></div>
        <div
          class="col text-right"
          v-if="anuncio.lojaOficial"
          title="O vendedor deste anúncio é uma empresa."
        >
          <img src="/img/check_oficial_icon.png" width="18" class="mr-1" />
          <small class="text-success font-weight-bold">LOJA OFICIAL</small>
        </div>
      </div>

      <div>{{ anuncio.nomeProprietario || "Proprietário não informado" }}</div>

      <div>
        {{ anuncio.cidade || "Cidade não informada" }} -
        {{ anuncio.ufSigla || "Estado não informado" }}
      </div>

      <router-link
        v-if="auth.isLoggedIn() && anuncio.proprietario"
        :to="getUrlChatProprioAnunciante()"
        class="btn btn-yellow btn-block mt-3"
        >Ver minhas negociações</router-link
      >
      <router-link
        v-else-if="auth.isLoggedIn()"
        :to="'/dashboard/chat?a=' + anuncio.id"
        class="btn btn-yellow btn-block mt-3"
        >Entrar em contato</router-link
      >
      <button v-else class="btn btn-yellow btn-block mt-3" @click="login">
        Faça login para contato
      </button>
      <hr />
      <div class="text-center">
        <button class="btn btn-link" @click="onClickAnunciosVendedor">
          Ver outros anúncios deste vendedor
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import auth from "../core/auth";

export default {
  props: ["anuncio"],
  data() {
    return {
      auth,
    };
  },
  methods: {
    login() {
      //this.$bvModal.show("modal-login");
      this.$router.push("/login?returnUrl=" + this.$route.path);
    },
    getUrlChatProprioAnunciante() {
      var bikeAnuncio = encodeURIComponent(
        btoa(this.anuncio.marca + " " + this.anuncio.modelo)
      );
      return "/dashboard/chat?a=" + this.anuncio.id + "&bike=" + bikeAnuncio;
    },
    onClickAnunciosVendedor() {
      window.localStorage.removeItem("filtroPesquisa");
      this.$router.push("/pesquisa?v=" + this.anuncio.idVendedor);
    },
  },
};
</script>
