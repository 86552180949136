<template>
  <div class="card shadow">
    <div class="card-body text-center text-md-left">
      <div class="row">
        <div class="col-md">
          <h3 class="text-success font-weight-bolder">
            {{ anuncio.marca }} - {{ anuncio.modelo }}
          </h3>
        </div>
        <div class="col-md-auto" v-if="anuncio.destaque">
          <span class="badge badge-pill badge-warning anuncio-destaque"
            >Anúncio destaque</span
          >
        </div>
      </div>

      <hr />
      <div class="font-weight-bold text-success mb-3">Sobre esta bike</div>
      <p class="title-case">
        {{
          (anuncio.descricao && anuncio.descricao.trim()) ||
            "Nenhuma informação adicional"
        }}
      </p>
      <!-- <p>Destinada para passeios em locais como parques, praias e ciclofaixas, a Bicicleta Aro 26 Caloi Snake é uma bike Off Road, com os melhores componentes Caloi, garantindo um pedalar mais seguro e prazeroso.</p>

      <p>Ela possui cor preta com pintura adesiva, corrente KMC e pedivela em aço. Seu movimento central possui 3 partes, enquanto o câmbio de 21 marchas é dividido em 3 velocidades na parte dianteira e 7 na parte traseira. Tudo feito de forma muito segura e simples, com a manopla Grip System. Por falar em segurança, a Caloi utiliza os melhores freios dianteiros e traseiros para a Snake: os freios V-Brake, em Alumínio.</p>

      <p>Com quadro tamanho 18 em aço carbono, a Snake Aro 26 possui capacidade máxima suportada de 100kg e é indicada para pessoas com mais de 1,60m de altura.</p> -->

      <div class="font-weight-bold text-success mb-3 mt-5">Características</div>

      <div class="row">
        <div class="col-lg-4 col-md-6 mb-3">
          <dt>Condição da bike:</dt>
          <dd>
            <span v-if="anuncio.condicaoBike == config.CONDICAO_BIKE_NOVA"
              >Nova</span
            >
            <span v-if="anuncio.condicaoBike == config.CONDICAO_BIKE_USADA"
              >Usada</span
            >
          </dd>
        </div>
        <div class="col-lg-4 col-md-6 mb-3" v-if="anuncio.tempoUsoMeses">
          <dt>Tempo de uso:</dt>
          <dd>{{ tempoUsoBike[anuncio.tempoUsoMeses] || "Não informado" }}</dd>
        </div>
        <div class="col-lg-4 col-md-6 mb-3">
          <dt>Tipo de bike:</dt>
          <dd>{{ anuncio.tipoBike || "Não informado" }}</dd>
        </div>

        <div class="col-lg-4 col-md-6 mb-3">
          <dt>Cor:</dt>
          <dd>{{ anuncio.cor || "Não informado" }}</dd>
        </div>
        <div class="col-lg-4 col-md-6 mb-3">
          <dt>Ano:</dt>
          <dd>{{ anuncio.anoBike || "Não informado" }}</dd>
        </div>
        <div class="col-lg-4 col-md-6 mb-3">
          <dt>Marca:</dt>
          <dd>{{ anuncio.marca || "Não informado" }}</dd>
        </div>
        <div class="col-lg-4 col-md-6 mb-3">
          <dt>Modelo:</dt>
          <dd>{{ anuncio.modelo || "Não informado" }}</dd>
        </div>
        <div class="col-lg-4 col-md-6 mb-3">
          <dt>Cambio dianteiro:</dt>
          <dd>
            <span v-if="anuncio.cambioDianteiro == 1">Uma marcha</span>
            <span v-else-if="anuncio.cambioDianteiro"
              >{{ anuncio.cambioDianteiro }} marchas</span
            >
            <span v-else>Não informado</span>
          </dd>
        </div>
        <div class="col-lg-4 col-md-6 mb-3">
          <dt>Cambio traseiro:</dt>
          <dd>
            <span v-if="anuncio.cambioTraseiro == 1">Uma marcha</span>
            <span v-else-if="anuncio.cambioTraseiro"
              >{{ anuncio.cambioTraseiro }} marchas</span
            >
            <span v-else>Não informado</span>
          </dd>
        </div>
        <div class="col-lg-4 col-md-6 mb-3">
          <dt>Tipo de freio:</dt>
          <dd>
            {{ anuncio.tipoFreio || "Não informado" }}
            <div v-if="anuncio.detalhesFreio" class="title-case">
              <small>{{ anuncio.detalhesFreio }}</small>
            </div>
          </dd>
        </div>
        <div class="col-lg-4 col-md-6 mb-3">
          <dt>Tipo de suspensão:</dt>
          <dd>
            {{ anuncio.tipoSuspensao || "Não informado" }}
            <div v-if="anuncio.detalhesSuspensao" class="title-case">
              <small>{{ anuncio.detalhesSuspensao }}</small>
            </div>
          </dd>
        </div>

        <div class="col-lg-4 col-md-6 mb-3">
          <dt>Tipo de roda/aro:</dt>
          <dd>{{ anuncio.tipoAro || "Não informado" }}</dd>
        </div>
        <div class="col-lg-4 col-md-6 mb-3">
          <dt>Tipo de garfo:</dt>
          <dd>{{ anuncio.tipoGarfo || "Não informado" }}</dd>
        </div>
        <div class="col-lg-4 col-md-6 mb-3">
          <dt>Tipo de quadro:</dt>
          <dd>{{ anuncio.tipoQuadro || "Não informado" }}</dd>
        </div>
        <div class="col-lg-4 col-md-6 mb-3">
          <dt>Tamanho do quadro:</dt>
          <dd v-if="anuncio.tamanhoQuadro">
            {{ descricaoTamanhoQuadro(anuncio.tamanhoQuadro) }}
            <button class="btn btn-sm" v-b-modal.modal-tamanho-quadro>
              <i class="fa fa-question-circle text-info"></i>
            </button>
          </dd>
          <dd v-else>Não informado</dd>
        </div>
        <div class="col-lg-4 col-md-6 mb-3">
          <dt>Pneu:</dt>
          <dd class="title-case">
            {{ anuncio.detalhesPneu || "Não informado" }}
          </dd>
        </div>
        <div class="col-lg-4 col-md-6 mb-3">
          <dt>Roda:</dt>
          <dd class="title-case">
            {{ anuncio.detalhesRoda || "Não informado" }}
          </dd>
        </div>
        <!-- <div class="col-lg-4 col-md-6 mb-3">
          <dt>Localização:</dt>
          <dd>{{anuncio.cidade  || 'Não informado'}}</dd>
        </div> -->
        <div class="col-lg-4 col-md-6 mb-3">
          <dt>Recomendado para:</dt>
          <dd>{{ anuncio.tipoRecomendacao || "Não informado" }}</dd>
        </div>
        <div class="col-lg-4 col-md-6 mb-3">
          <dt>Possui nota fiscal:</dt>
          <dd>
            <span v-if="anuncio.possuiNotaFiscal">Sim</span>
            <span v-else-if="anuncio.possuiNotaFiscal === undefined"
              >Não informado</span
            >
            <span v-else>Não</span>
          </dd>
        </div>
        <div class="col-lg-4 col-md-6 mb-3">
          <dt>Tipo de entrega preferida:</dt>
          <dd>{{ anuncio.tipoEntrega || "Não informado" }}</dd>
        </div>
      </div>
    </div>
    <ModalTamanhoQuadro />
  </div>
</template>

<script>
import tempoUsoBike from "../core/tempoUsoBike";
import config from "../config";

import ModalTamanhoQuadro from "../components/ModalTamanhoQuadro";

export default {
  props: ["anuncio"],
  components: {
    ModalTamanhoQuadro,
  },
  data() {
    return {
      tempoUsoBike,
      config,
    };
  },
  methods: {
    descricaoTamanhoQuadro(tamanho) {
      var item = config.TAMANHOS_QUADRO.find((item) => item.value == tamanho);
      return item?.text || "Não informado";
    },
  },
};
</script>
